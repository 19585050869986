import React, { useState } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import request from '../../../utils/Request';
import UserForm from './forms/User';
import ProfileForm from './forms/Profile';
import ErrorAlert from '../../utils/ErrorAlert';

export default ({ history }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    email: '',
    password: ''
  });
  const [userValid, setUserValid] = useState(false);

  const [profile, setProfile] = useState({
    name: '',
    address: '',
    city: '',
    postal: '',
    province: '',
    countryId: 1,
    country: '',
    phone: '',
    mobile: ''
  });
  const [profileValid, setProfileValid] = useState(false);

  const saveProfile = async (newUser) => {
    try {
      const result = await request.post('/ownerprofile', {
        ...profile,
        userId: newUser.id
      });
      if (result) {
        history.push(`/admin/owner/${result.id}`);
      } else {
        throw Error();
      }
    } catch (e) {
      setError('Error saving owner profile');
    }
  };

  const create = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const result = await request.post(
        `/user?role=Owner&userName=${user.email}`,
        user
      );
      setLoading(false);
      if (result && result.user) {
        saveProfile(result.user);
      } else {
        setError('Error creating new user');
      }
    } catch (e) {
      setLoading(false);
      setError('Error creating new user. User may already exist.');
    }
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <UserForm user={user} setUser={setUser} setValid={setUserValid} />
          <br />
          <ProfileForm
            profile={profile}
            setProfile={setProfile}
            setValid={setProfileValid}
          />
          <Grid>
            <Row>
              <Col md={12}>
                {!loading && (
                  <Button
                    bsStyle="primary"
                    bsSize="large"
                    block
                    type="submit"
                    disabled={!userValid || !profileValid}
                    onClick={create}>
                    Create Owner
                  </Button>
                )}
                {loading && <div className="loader" />}
              </Col>
            </Row>
            <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
